import React from 'react'
import mask_img from '../../../assets/images/home/sec2-img.png'
import line from '../../../assets/images/home/line.png'
import { Link } from 'react-router-dom';
import Slide from 'react-reveal/Slide';

const Section2=(props) => {
    const { myRef } = props
    return (
        <div className='section2'  ref={myRef}>
            <Slide bottom>
                <div className='container'>

                    <div className='sec2-wrap'>
                        <div className='row dv-center'>
                            <div className='col-lg-6'>
                                <div className='img-wrapper'>
                                    <img src={mask_img} alt='slider1' className='img-fluid c-img' />
                                    <img src={line} alt='slider1' className='img-fluid line-img' />
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='content-wrapper'>
                                    <h2>ClaimBridge</h2>
                                    <p>The genesis for ClaimBridge, a Retrieve Remit Company, was the opportunity to partner with an industry leading Electronic Health Record (EHR)
                                        company to provide Revenue Cycle Automation and Claim Payment Acceleration.  A survey of their clients showed overwhelmingly
                                        that the amount of time to get Claim reimbursed is the biggest pain point and making sense of the claim-to-cash data, both
                                        paper and electronic is a close second.</p>
                                    <div className='btn-wrapper'>
                                        <Link to='/about' className='btn prim-btn'>Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* // ecg line animation start */}
                    {/* <div class="intro-block d-none d-lg-block">

                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" >
                            <g>
                                <polyline class="ekg" points="486.6,113.8 328.2,113.8 310.3,132.3 296,70.7 246.8,127.4 241.6,120.2 233.9,166.4 227,27.6 
        213.2,118.3 211.8,112.3 205.1,126.1 198.2,108.5 194.1,124.4 184.5,92.9 174.1,113 4.3,113 	" />
                            </g>
                        </svg>

                    </div> */}
                    {/* // ecg line animation end */}

                </div>
            </Slide>

            <div className='bg-blue'>
                <div className='container'>
                    <Slide bottom>
                        <div className='content-wrapper'>
                            <p>Let us provide you with High-Quality EOB conversion, Claim and Remit
                                Matching, and Payment and Remit Reassociation!</p>
                        </div>
                    </Slide>
                </div>
            </div>

        </div>
    )
}

export default Section2;
